import BIcon from '@components/bIcon'
import LoadingConditional from '@components/LoadingConditional'
import { blocksIcons } from '@constants/icons'
import { DAYS_TO_INVITATION_EXP } from '@constants/index'
import useReferred from '@hooks/useReferred'
import { Invitation, InvitationStatus } from '@models/Invitation'
import { Delay } from '@shared/index'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ButtonAction from './ButtonAction'

export default function ActionStatusExpired({
  invitation
}: {
  invitation: Invitation
}) {
  const { formatMessage: f } = useIntl()

  const { submit } = useReferred()
  const [timeToExp, setTimeToExp] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const exp = moment(invitation.expiration)
      .add(DAYS_TO_INVITATION_EXP, 'days')
      .fromNow()

    setTimeToExp(exp)
  }, [invitation])

  useEffect(() => {
    const tick = setTimeout(() => {
      setIsLoading(false)
    }, 5000)

    return () => clearTimeout(tick)
  }, [isLoading])

  const referrerAction = () => {
    submit(invitation)
    setIsLoading(true)
  }

  if (invitation.status !== InvitationStatus.Expired) return
  return (
    <>
      <span className="text-xs text-accents-8 mr-2">
        {f({ id: 'delete' })} {timeToExp}
      </span>

      <LoadingConditional isLoading={isLoading}>
        <ButtonAction
          value={invitation}
          tooltipContent={'Refer again'}
          action={referrerAction}
        >
          <BIcon icon={blocksIcons.referred} />
        </ButtonAction>
      </LoadingConditional>
    </>
  )
}
