export enum InvitationStatus {
  Expired,
  Pending,
  TrialPeriod,
  Finished
}

export type InvitationStatusString = keyof typeof InvitationStatus

export interface Invitation {
  email: string
  token: string
  status: InvitationStatus
  referredBy: string
  expiration: string
}

export interface JwtType {
  exp: number
  iat: number
  nbf: number
  unique_name: string
}
