import { useClipboard } from '@hooks/useClipboard'
import { Tooltip } from '@nextui-org/react'
import { ClassNames } from '@shared/DataValidation'

import React, { ReactNode, useEffect } from 'react'
import EmbedButton from './EmbedButton'
import CopyIcon from './Icons/CopyIcon'

export default function Snippet({
  children,
  className = '',
  text
}: {
  children?: ReactNode
  className?: string
  text: string
}) {
  const { onCopy, setValue, hasCopied } = useClipboard('')

  useEffect(() => {
    setValue(text)
  }, [text])

  return (
    <div
      className={ClassNames(
        className,
        'flex items-center h-10 mb-4 md:mb-0 p-[10px] rounded-xl bg-green-600 dark:bg-green-500 dark:bg-opacity-40 text-neutral-600 dark:text-neutral-300'
      )}
    >
      <pre className="p-0 m-0">{text}</pre>
      {children}

      <Tooltip
        content={hasCopied === true ? 'Copied' : 'Copy'}
        color="invert"
        placement="bottom"
        visible={true}
      >
        <EmbedButton
          onClick={(e) => {
            onCopy()
            e.preventDefault()
          }}
        >
          <CopyIcon />
        </EmbedButton>
      </Tooltip>
    </div>
  )
}
