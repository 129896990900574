import BIcon from '@components/bIcon'
import { blocksIcons } from '@constants/icons'
import useReferred from '@hooks/useReferred'
import { Invitation, InvitationStatus } from '@models/Invitation'
import ButtonAction from './ButtonAction'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { claimRewardRequest, getRewards } from '@store/rewards/rewards.slice'
import { ClaimRewardRequest } from '@models/dtos/ClaimRewardRequest'
import { Reward, RewardStatusType } from '@models/Reward'
import { useEffect, useState } from 'react'
import { Loading } from '@nextui-org/react'
import LoadingConditional from '@components/LoadingConditional'

export default function ActionClaimReward({
  invitation
}: {
  invitation: Invitation
}) {
  const dispatch = useAppDispatch()
  const { rewards, isLoading, isClaimed } = useAppSelector(
    (state) => state.rewards
  )
  const [currentReward, setCurrentReward] = useState<Reward>()

  const claimReward = (data: Reward) => {
    const req: ClaimRewardRequest = { token: data.token }
    dispatch(claimRewardRequest(req))
  }

  useEffect(() => {
    if (rewards !== undefined && currentReward === undefined) {
      setCurrentReward(rewards.find((x) => x.token === invitation.token))
    }

    if (isClaimed === true) {
      dispatch(getRewards())
    }
  }, [rewards, currentReward, invitation, isClaimed])

  if (invitation.status !== InvitationStatus.Finished) return

  return (
    <LoadingConditional
      isLoading={isLoading === true && currentReward !== undefined}
    >
      <ButtonAction
        disabled={currentReward?.status !== RewardStatusType.Pending ?? false}
        value={invitation}
        tooltipContent={
          currentReward?.status !== RewardStatusType.Pending
            ? 'Reward redeemed'
            : 'Claim reward'
        }
        action={() => claimReward(currentReward)}
      >
        <BIcon icon={blocksIcons.gift} />
      </ButtonAction>
    </LoadingConditional>
  )
}
