import { ClassNames } from '@shared/DataValidation'
import React, { MouseEventHandler, MutableRefObject, ReactNode } from 'react'

export default function EmbedButton({
  children,
  refButton,
  className,
  text,
  onClick,
  disabled = false
}: {
  children?: ReactNode
  refButton?: MutableRefObject<HTMLButtonElement>
  className?: string
  text?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}) {
  return (
    <button
      ref={refButton}
      disabled={disabled}
      className={ClassNames('nextui-snippet-copy-button', className)}
      onClick={onClick}
    >
      {children !== undefined ? children : text}
    </button>
  )
}
