import BInput from '@components/BInput'
import ListStatus from '@components/Referred/ListStatus'
import Snippet from '@components/Snippet'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import BButton from '@components/bButton/BButton'
import SectionWidget from '@components/sectionWidget'
import { blocksIcons } from '@constants/icons'
import useReferred from '@hooks/useReferred'
import { InvitationStatus } from '@models/Invitation'
import { InvitationDto } from '@models/dtos/InvitationDto'
import { useTour } from '@reactour/tour'
import GenerateShareUrl from '@shared/GenerateShareUrl'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getInvitations } from '@store/referred/referred.slice'
import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function Referred() {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()

  const { submit, isLoading, invitations, currentInvitation } = useReferred()
  const [invitationUrl, setInvitationUrl] = useState<string>()

  const initValue: InvitationDto = {
    email: ''
  }

  const referredFormSchema = yup.object({
    email: yup
      .string()
      .email(
        f({
          id: 'Please enter valid email'
        })
      )
      .required(
        f({
          id: 'Invited email is required'
        })
      )
  })

  const handleSubmit = (
    value: InvitationDto,
    actions: FormikHelpers<InvitationDto>
  ) => {
    submit(value)
    actions.resetForm()
  }

  useEffect(() => {
    if (invitations === undefined || currentInvitation !== undefined) {
      dispatch(getInvitations())
    }

    if (currentInvitation?.token === undefined) return
    const finalUrl = GenerateShareUrl(currentInvitation.token)
    setInvitationUrl(finalUrl)
  }, [currentInvitation])

  return (
    <div id="referred">
      <div className="referred-header mb-[60px]">
        <h1 className="text-xl md:text-3xl">{f({ id: 'Referred panel' })}</h1>
      </div>
      <div className="referred-container md:flex md:flex-wrap md:gap-[30px]">
        <SectionWidget
          title={f({ id: 'Invite friends' })}
          className="md:w-full lg:w-auto mb-0 shadow-xl"
        >
          <div className="w-full flex flex-wrap gap-6">
            <SubsectionWrapper className="w-full lg:w-[480px] min-h-[450px]">
              <div className="mb-4 flex gap-6">
                <p className="font-bold">{f({ id: 'All yours referred' })}</p>
              </div>
              <Formik
                initialValues={initValue}
                validationSchema={referredFormSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form className="w-full">
                    <div className="md:flex items-end gap-4 mb-6">
                      <BInput
                        className="input-email mb-4 md:mb-0"
                        fullWidth
                        label={f({ id: 'Invited email' })}
                        name="email"
                        value={formik.values?.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email as string}
                        contentLeft={blocksIcons.mail}
                      />
                      <BButton
                        auto
                        type="submit"
                        className="btn-invite min-w-[130px]"
                        isLoading={isLoading}
                        text={f({ id: 'Make an invitation' })}
                      />
                    </div>
                    {currentInvitation !== undefined &&
                      currentInvitation.status === InvitationStatus.Pending && (
                        <div className="w-full mb-6">
                          <p className="nextui-input-block-label text-sm pl-1 mb-1.5">{`${f(
                            {
                              id: 'Invitation for'
                            }
                          )} ${String(currentInvitation.email)}`}</p>
                          <Snippet text={invitationUrl} />
                        </div>
                      )}
                  </Form>
                )}
              </Formik>

              {invitations?.length > 0 && (
                <>
                  <div className="mb-4 flex justify-between gap-6">
                    <p className="font-bold">
                      {f({ id: 'Invitations status' })}
                    </p>
                    <p className="text-sm text-neutral-400">
                      <span className="pr-1">{invitations?.length ?? 0}</span>
                      {invitations?.length > 1
                        ? f({ id: 'invitations' })
                        : f({ id: 'invitation' })}
                    </p>
                  </div>
                  <ListStatus invitations={invitations} />
                </>
              )}
            </SubsectionWrapper>
          </div>
        </SectionWidget>
      </div>
    </div>
  )
}
