import { Loading } from '@nextui-org/react'
import { ReactNode } from 'react'

export default function LoadingConditional({
  children,
  isLoading
}: {
  children: ReactNode
  isLoading: boolean
}) {
  return (
    <>
      {isLoading ? (
        <Loading color="currentColor" size="sm" type="points-opacity" />
      ) : (
        children
      )}
    </>
  )
}
