import { Alert, AlertTypes } from '@models/dtos/Alert'
import { InvitationDto } from '@models/dtos/InvitationDto'
import { Invitation } from '@models/Invitation'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import {
  claimReferrerReward,
  generateInvitation
} from '@store/referred/referred.slice'
import { useIntl } from 'react-intl'

export interface UseReferredType {
  isLoading: boolean
  invitations: Invitation[]
  currentInvitation: Invitation
  submit: (invitation: InvitationDto) => void
  dispatchNotificationAdd: (invitation: InvitationDto, message: string) => void
  claimReward: (invitation: Invitation) => void
}

export default function useReferred(): UseReferredType {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()
  const { isLoading, invitations, currentInvitation } = useAppSelector(
    (state) => state.referred
  )

  const dispatchNotificationAdd = (
    invitation: InvitationDto,
    message: string
  ) => {
    dispatch({
      type: 'notifications/add',
      payload: new Alert(
        f({ id: 'Referred' }),
        message,
        AlertTypes.Info,
        invitation.email
      )
    })
  }

  const submit = (invitation: InvitationDto) => {
    dispatch(generateInvitation(invitation))
    dispatchNotificationAdd(
      invitation,
      f({ id: 'New invitation has been created' })
    )
  }

  const claimReward = (invitation: Invitation) => {
    dispatch(claimReferrerReward(invitation))
    dispatchNotificationAdd(
      invitation,
      f({ id: 'Referrer reward has been redeemed' })
    )
  }

  return {
    isLoading,
    invitations,
    currentInvitation,
    submit,
    dispatchNotificationAdd,
    claimReward
  }
}
