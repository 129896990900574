import { InvitationStatusString } from '@models/Invitation'
import { ClassNames } from '@shared/DataValidation'
import { useIntl } from 'react-intl'

export default function StatusLabel({
  status
}: {
  status: InvitationStatusString
}) {
  const { formatMessage: f } = useIntl()

  const colors = new Map<InvitationStatusString, string>()
  colors.set('Expired', 'bg-red-100')
  colors.set('Pending', 'bg-orange-100')
  colors.set('TrialPeriod', 'bg-blue-100')
  colors.set('Finished', 'bg-green-250')

  return (
    <span className="status-label text-ss font-bold flex items-center w-fit p-1 pr-2 rounded-xl bg-blue-200 bg-opacity-20 dark:bg-neutral-900 dark:bg-opacity-30">
      <span
        className={ClassNames(
          'circle w-3 h-3 mr-1 rounded-full',
          colors.get(status)
        )}
      ></span>
      <span>{f({ id: status }).toUpperCase()}</span>
    </span>
  )
}
