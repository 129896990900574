import EmbedButton from '@components/EmbedButton'
import { Tooltip } from '@nextui-org/react'
import { ClassNames } from '@shared/index'
import React, { MutableRefObject, ReactNode } from 'react'

export default function ButtonAction({
  children,
  refButton,
  value,
  tooltipContent,
  action,
  disabled = false
}: {
  children: ReactNode
  refButton?: MutableRefObject<HTMLButtonElement>
  value: unknown
  tooltipContent: string
  action: (value: unknown) => void
  disabled?: boolean
}) {
  return (
    <Tooltip content={tooltipContent} color="invert">
      <EmbedButton
        refButton={refButton}
        disabled={disabled}
        className={ClassNames('button-action p-0')}
        onClick={() => action(value)}
      >
        {children}
      </EmbedButton>
    </Tooltip>
  )
}
