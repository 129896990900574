import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import copy from 'copy-to-clipboard'
import { AUTO_CLOSE_NOTIFICATION_DELAY } from '@constants/index'

export interface UseClipboardType {
  value: string
  setValue: Dispatch<SetStateAction<string>>
  onCopy: () => void
  hasCopied: boolean
}

export function useClipboard(
  value: string,
  timeout = AUTO_CLOSE_NOTIFICATION_DELAY
): UseClipboardType {
  const [hasCopied, setHasCopied] = useState(false)
  const [valueState, setValueState] = useState(value)
  useEffect(() => setValueState(value), [value])

  const onCopy = useCallback(() => {
    const didCopy = copy(valueState)
    setHasCopied(didCopy)
  }, [valueState])

  useEffect(() => {
    let timeoutId: number | undefined

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }

    return () => {
      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [timeout, hasCopied])

  return {
    value: valueState,
    setValue: setValueState,
    onCopy,
    hasCopied
  }
}
