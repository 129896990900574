import { Invitation } from '@models/Invitation'
import { ClassNames } from '@shared/DataValidation'
import { MutableRefObject } from 'react'
import InvitationWrapper from './InvitationWrapper'

export default function ListStatus({
  className,
  refButton,
  invitations
}: {
  className?: string
  refButton?: MutableRefObject<HTMLButtonElement>
  invitations: Invitation[]
}) {
  return (
    <div className={ClassNames(className, 'referred-status-list')}>
      <div className="max-h-[395px] overflow-y-auto">
        <ul className="flex flex-col gap-2">
          {invitations.map((invitation) => (
            <InvitationWrapper
              refButton={refButton}
              key={invitation.token}
              invitation={invitation}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
