import BIcon from '@components/bIcon'
import CopyIcon from '@components/Icons/CopyIcon'
import LoadingConditional from '@components/LoadingConditional'
import StatusLabel from '@components/StatusLabel'
import { blocksIcons } from '@constants/icons'
import { DAYS_TO_INVITATION_EXP } from '@constants/index'
import { useClipboard } from '@hooks/useClipboard'
import useReferred from '@hooks/useReferred'
import {
  Invitation,
  InvitationStatus,
  InvitationStatusString
} from '@models/Invitation'
import GenerateShareUrl from '@shared/GenerateShareUrl'
import { useAppDispatch } from '@store/hooks'
import moment from 'moment'
import { MutableRefObject, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ActionClaimReward from './ActionClaimReward'
import ActionStatusExpired from './ActionStatusExpired'
import ButtonAction from './ButtonAction'

export default function InvitationWrapper({
  invitation,
  refButton
}: {
  invitation: Invitation
  refButton?: MutableRefObject<HTMLButtonElement>
}) {
  const { formatMessage: f } = useIntl()
  const { onCopy, setValue, hasCopied } = useClipboard('')
  const { submit, dispatchNotificationAdd, claimReward } = useReferred()

  const copyAction = (invitation: Invitation) => {
    onCopy()
    dispatchNotificationAdd(
      invitation,
      f(
        { id: 'Invitation for {email} are copy to clipboard' },
        { email: invitation.email }
      )
    )
  }

  useEffect(() => {
    const finalUrl = GenerateShareUrl(invitation.token)
    setValue(finalUrl)
  }, [invitation, onCopy, setValue])

  return (
    <li
      className="w-full m-0 px-4 py-2 rounded-[20px] border-solid border-2"
      style={{
        borderColor: 'var(--nextui-colors-border)'
      }}
    >
      <p className="text-sm mb-2">{invitation.email}</p>
      <span className="flex justify-between">
        <StatusLabel
          status={InvitationStatus[invitation.status] as InvitationStatusString}
        />
        <span className="actions-wrapper flex self-center">
          {invitation.status === InvitationStatus.Pending && (
            <ButtonAction
              refButton={refButton}
              value={invitation}
              tooltipContent={hasCopied === true ? 'Copied' : 'Copy url'}
              action={() => copyAction(invitation)}
            >
              <CopyIcon />
            </ButtonAction>
          )}

          <ActionStatusExpired invitation={invitation} />
          <ActionClaimReward invitation={invitation} />
        </span>
      </span>
    </li>
  )
}
